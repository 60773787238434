.SkeletonPosts {
    
    padding-top: 5rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
}

@media screen and (max-width: 850px) {
    .SkeletonPosts {
        grid-template-columns:  1fr;
    }
  }