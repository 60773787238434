.App {
  padding-top: 6rem;
}

.Page {
  padding: 1rem 10vw;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-family: serif;
}

button {
  padding: 1rem;
  border: none;
}

@media screen and (max-width: 850px) {
  .Page{
    padding: 1rem 4vw;
  }
}