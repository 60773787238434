.Post .BreadCrumbs {
    margin-bottom: 2rem;
}

.Post .BreadCrumbs {
    font-family: sans-serif;
    color: #A0A0A0;
    margin-bottom: 2rem;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  
  .BreadCrumbs a {
    color: #000;
  }
  
.Post .BreadCrumbs a:hover {
    text-decoration: underline;
}

.Post .Body img {
    width: 75%;
    float: right;
    text-justify: newspaper;
}

.Post .Comments {
    padding-top: 2rem;
}

.Post .Comments .Comment {
    margin-top: 1rem;
}

.Post .Comments .NewComment {
    border: 1px solid #0000005f;
    margin-top: 1rem;
    padding: 1rem;
}

.Post .Comments .NewComment textarea, .Post .Comments .NewComment input{
    display: block;
    width: 100%;
    border: none;
    resize: none;
}

.Post .Comments .NewComment textarea:focus, .Post .Comments .NewComment input:focus {
    outline: none;
}

.Post .Comments .NewComment input {
    border-top: 1px solid #0000005f;
    padding: 0.5rem 0.5rem;
}

.Post .Comments .Submit{
    text-align: right;
    padding: 0.5rem 0;
}

/* .Post .Comments .Submit button {
    padding: 0.5rem 1rem;
    background-color: #000;
    color: #fff;
    border: none;
} */

@media screen and (max-width: 1300px) {
    .Post .Body img {
        width: 100%;
        clear: both;
        margin-bottom: 1rem;
    }
}