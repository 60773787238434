

.Posts .PostsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
}

.Posts .Post img{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.Posts .Post .Title{
    text-transform: capitalize;
}

.Posts .Post .ReadMore button{
    background-color: #000;
    color: #fff;
}

.Posts .Post .Tags {
    display: flex;
    column-gap: 1rem;
}

.Posts .Post .Tags .Tag {
    background-color: #dedede;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0.4rem;
}

@media screen and (max-width: 1150px) {
    .Posts .PostsContainer {
        grid-template-columns: 1fr;
    }

    .Posts .Post img{
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
}

@media screen and (max-width: 850px) {

    .Posts .Post img{
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
}
