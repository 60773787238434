.Navbar {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0.5rem 10vw;

    background-color: #fff;
    border-bottom: 1px solid #000;

    transition: all ease-in-out 0.2s;

    z-index: 200
}

.NavbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    z-index: 100;
}

.Search {
    position: absolute;
    left: 0;
    bottom: -33px;
    width: 100%;

    z-index: 20;
}


.Navbar input {
    height: 32px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0 !important;
    padding-left: 10vw;

    font-size: 16px;
}

.Navbar input:focus {
    outline: none
}


.Menu {
    background-color: #eaeaea;
    color: #000;

    z-index: 100;
    position: fixed;
    top: 4rem;
    width: 100%;
    height: 100vh;

    transition: right ease-in-out 0.2s;
}

.Menu nav ul {
    height: 100vh;
    list-style-type: none;
    padding: 1rem 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.Menu nav ul li {
    font-size: 1rem;
    border-bottom: 1px solid;
}

@media screen and (max-width: 850px) {
    .Navbar, .Navbar .Search input{
      padding-left: 4vw;
    }
    .Menu {
        background-color: #eaeaea;
        color: #000;
    
        z-index: 100;
        position: fixed;
        top: 2rem;
        width: 100%;
        height: 100vh;
    
        transition: right ease-in-out 0.2s;
    }
  }